import React, { Component } from "react";
import { Button, Card, Form, FormLabel, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Cookies from "js-cookie";
import Select from "react-select";
import { toast } from "react-toastify";
import appointmentsApi from "../api/appointments.api";
import { Container, InputGroup, FormControl } from "react-bootstrap";
import usersApi from "../api/users.api";
import correctPhone from "../utils/phone.check";
import spinner from "../../src/images/2.gif";
export default class NewAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      appointment_tpyes: [],
      pacient_name: "",
      pacient_city: "",
      pacient_points: 0,
      phone_search: "",
      phone_search_err: "",
      appointment: null,
      appointmentErr: "",
      usePoints: false,
      charge_price: 0,
      pacient: null,
      admin_name: "",
      isLoading: false,
      pacient_err: "",
      pointsErr: "",
      pointsToUse: 0,
      invalidPointsErr: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.getPatient = this.getPatient.bind(this);
    this.onChange = this.onChange.bind(this);
    this.appointmentChange = this.appointmentChange.bind(this);
    this.calculate = this.calculate.bind(this);
    this.insertAppointment = this.insertAppointment.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async getTypes() {
    try {
      const result = await appointmentsApi.getAppointmentTypes();
      this.setState({ appointment_tpyes: result.types });
    } catch (error) {
      toast.error("Грешkа при преземањето на прегледи");
    }
  }

  calculate(value) {
    const usePoints = value ? value : this.state.userPoints;
    const currentPrice = usePoints
      ? this.state.appointment.price - this.state.pacient_points
      : this.state.appointment.price;
    this.setState({
      charge_price: parseFloat(currentPrice) < 0 ? 0 : currentPrice,
    });
  }

  async getPatient() {
    try {
      this.setState({ phone_search_err: "", pacient_err: "", pointsErr: "" });
      if (
        this.state.phone_search != "" &&
        correctPhone(this.state.phone_search)
      ) {
        const result = await usersApi.findByPhone(this.state.phone_search);
        if (result.data.user) {
          this.setState({
            pacient_name: result.data.user.full_name,
            pacient_city: result.data.user.city,
            pacient_points: result.data.user.points,
            pacient: result.data.user,
            pointsToUse: result.data.user.points,
          });
        } else {
          this.setState({
            phone_search_err: "Не е пронајден пациент со овој телефонски број",
          });
        }
      } else {
        this.setState({
          phone_search_err: "Не правилен формат на телефонски број",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Грешка при пребарувањето");
    }
  }

  async appointmentChange(e) {
    try {
      const result = await appointmentsApi.getAppoiintment(e.value);
      this.setState({ appointment: result.appointment });
      this.calculate();
    } catch (error) {
      console.log(error);
      toast.error("Грешка при превземувањето на преглед");
    }
  }

  async componentDidMount() {
    this.setState({ admin_name: Cookies.get("currentUser") });
    await this.getTypes();
  }
  async insertAppointment() {
    this.setState({ phone_search_err: "", pacient_err: "", pointsErr: "" });
    try {
      if (this.validateForm()) {
        this.setState({ isLoading: true });
        const result = await appointmentsApi.newAppointment(
          this.state.pacient,
          this.state.appointment,
          this.state.usePoints,
          this.state.admin_name,
          this.state.pointsToUse
        );
        if (result.status == 200) {
          toast.success("Прегледот беше успешно внесен");
          setTimeout(() => {
            window.location = "/appointments";
          }, 2000);
        }
      }
    } catch (error) {
      toast.error("Грешка при внесување на прегледот");
    }
  }

  validateForm() {
    if (this.state.pacient_name == "") {
      this.setState({
        pacient_err: "Пребарајте пациент за да внесите поени",
      });
      return false;
    }
    if (this.state.appointment == null) {
      this.setState({ appointmentErr: "Одберете тип на преглед" });
      return false;
    }
    if (this.state.pacient_points == 0 && this.state.usePoints) {
      this.setState({
        pointsErr: "Пациентот нема преостанати поени на располагање",
      });
      return false;
    }
    if (
      parseInt(this.state.pointsToUse) > parseInt(this.state.pacient_points)
    ) {
      const points = this.state.pacient_points;
      this.setState({
        invalidPointsErr: `Пациентот можи да искористи максимум ${points} поени`,
      });
      return false;
    }
    if (
      parseInt(this.state.pointsToUse) > parseInt(this.state.appointment.price)
    ) {
      const points = this.state.pacient_points;
      this.setState({
        invalidPointsErr: `Искористените поени се поголеми од цената на прегледот`,
      });
      return false;
    }
    return true;
  }
  onChange = () => {
    this.setState({ usePoints: !this.state.usePoints });
    this.calculate(!this.state.usePoints);
  };
  render() {
    if (this.state.isLoading) {
      return (
        <Container>
          <img className="spinner" src={spinner}></img>
        </Container>
      );
    } else {
      return (
        <Container>
          {/* Stack the columns on mobile by making one full-width and the other half-width */}
          <Card className="mt-5">
            <h4 className="appointments-head4">Пациент</h4>
            <Card.Body>
              <Col xs={12} md={12}>
                <Form.Group controlId="">
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder="Телефонски број на пациент"
                      aria-label="Телефонски број на пациент"
                      aria-describedby="basic-addon2"
                      type="number"
                      name="phone_search"
                      onChange={this.handleChange}
                    />
                    <InputGroup.Append>
                      <Button
                        onClick={this.getPatient}
                        variant="outline-primary"
                      >
                        <i className="material-icons">search</i>Пребарај
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                  <p className="error">{this.state.phone_search_err}</p>
                </Form.Group>
              </Col>
              <Col xs={6} md={4} className="pt-3">
                <FormLabel type="label" className="label-form">
                  Име на пациент : {this.state.pacient_name}
                </FormLabel>
              </Col>
              <Col xs={6} md={4} className="pt-3">
                <FormLabel type="label" className="label-form">
                  Град : {this.state.pacient_city}
                </FormLabel>
              </Col>
              <Col xs={6} md={4} className="pt-3">
                <FormLabel type="label" className="label-form">
                  Моментални поени : {this.state.pacient_points}
                </FormLabel>
              </Col>
              <p className="error">{this.state.pacient_err}</p>
            </Card.Body>
          </Card>

          {/* Columns are always 50% wide, on mobile and desktop */}
          <Card className="mt-3">
            <h4 className="appointments-head4">Тип на преглед</h4>
            <Card.Body>
              <Row>
                <Col xs={12} md={6}>
                  {" "}
                  <Select
                    options={this.state.appointment_tpyes}
                    isSearchable="true"
                    onChange={this.appointmentChange}
                    placeholder="Одберете тип на преглед..."
                  ></Select>
                  <p className="error">{this.state.appointmentErr}</p>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col>
                  <FormLabel>
                    Цена на преглед :{" "}
                    <span className="dark-label">
                      {this.state.appointment
                        ? this.state.appointment.price + " ден"
                        : null}
                    </span>
                  </FormLabel>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <FormLabel>
                    Поени :{" "}
                    <span className="dark-label">
                      {this.state.appointment
                        ? this.state.appointment.points
                        : null}
                    </span>
                  </FormLabel>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mt-3">
            <h4 className="appointments-head4">Пресметка</h4>
            <Card.Body>
              <Row>
                {this.state.appointment ? (
                  <Col>
                    <FormLabel>
                      <input
                        type="checkbox"
                        checked={this.state.usePoints}
                        onChange={this.onChange}
                        className="mr-4"
                      />
                      Искористи поени?
                    </FormLabel>

                    <FormControl
                      type="number"
                      className="col-md-2"
                      value={this.state.pointsToUse}
                      disabled={this.state.usePoints == false ? "disabled" : ""}
                      onChange={this.handleChange}
                      name="pointsToUse"
                    ></FormControl>
                    <p className="error">{this.state.invalidPointsErr}</p>
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col>
                  <FormLabel>
                    За наплата :{" "}
                    <span className="dark-label">
                      {this.state.charge_price}
                    </span>
                  </FormLabel>
                </Col>
                <Col>
                  <Button
                    variant="btn btn-primary"
                    onClick={this.insertAppointment}
                  >
                    <i className="material-icons">done</i>
                    Внеси
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      );
    }
  }
}
