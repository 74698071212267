import React, { Component } from "react";
import { Row, Col, Form, FormControl, Button, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import MessagesApi from "../api/messages.api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Cookies from "js-cookie";
import ExportMessages from "./DownloadMessages";

export default class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      totalCount: 10,
      searchText: "",
      currentMessageId: 0,
      isSuperAdmin: JSON.parse(Cookies.get("isSuperAdmin")),
    };

    this.getMessages = this.getMessages.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
  }
  setCurrentMessage(messageId) {
    this.setState({
      currentMessageId: messageId,
    });
    this.setIsOpen(true);
  }

  openModal() {
    this.setIsOpen(true);
  }
  closeModal() {
    this.setIsOpen(false);
  }
  setIsOpen(value) {
    this.setState({ modalIsOpen: value });
  }
  async getMessages(pageNumber) {
    try {
      const result = await MessagesApi.getAllMessages(
        pageNumber ? pageNumber : this.state.page,
        this.state.totalCount,
        this.state.searchText
      );
      this.setState({ messages: result.messages });
    } catch (error) {
      console.log(error);
      toast.error("Грешка при превземањето на пораките");
    }
  }
  async componentDidMount() {
    await this.getMessages();
  }

  async handlePageClick(e) {
    await this.getMessages(e.selected + 1);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  async deleteComment() {
    try {
      var result = await MessagesApi.deleteMessage(this.state.currentMessageId);
      console.log(result);
      if (result.status == 200) {
        toast.success("Коментарот беше успешно избришан");
        this.setState({ currentMessageId: 0, modalIsOpen: false });
        this.getMessages();
      }
    } catch (error) {
      console.log(error);
      toast.error("Грешка при бришење коментар");
    }
  }
  render() {
    Modal.setAppElement("#root");
    const customStyles = {
      content: {
        top: "30%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };
    return (
      <Row className="table-section">
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Example Modal"
          style={customStyles}
        >
          <Form>
            <Form.Group as={Row}>
              <Form.Label className="black">
                Дали сте сигурни дека сакате да го избришите коментарот ?
              </Form.Label>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextPassword">
              <Button
                onClick={this.deleteComment}
                className="mr-2 btn btn-success"
              >
                Да
              </Button>
              <Button onClick={this.closeModal} className="btn btn-danger">
                Откажи
              </Button>
            </Form.Group>
          </Form>
        </Modal>
        <ToastContainer />
        <Col lg={12}>
          <figure className="icon-group">
            <h2 className="heading ml-3">Коментари / Прашања</h2>
          </figure>

          <Form inline onSubmit={this.handleSubmit}>
            <FormControl
              type="number"
              name="searchText"
              placeholder="Пребарај тел.број"
              className="mr-sm-2"
              onChange={this.handleChange}
            />
            <Button
              variant="btn mr-2 button-standard"
              onClick={() => this.getMessages()}
            >
              <i className="material-icons">search</i>
              Пребарај
            </Button>
            <ExportMessages></ExportMessages>
            <Button
              className="btn mr-2 button-standard"
              onClick={() => this.getMessages()}
            >
              <i className="material-icons">refresh</i> Освежи
            </Button>
          </Form>
          <section className="list-box mt-3">
            <Table responsive="xl" className="mb-5">
              <thead className="table-head">
                <tr>
                  <th>Име на испраќач</th>
                  <th>Тел број</th>
                  <th>Коментар/Прашање</th>
                  <th>Испратено на</th>
                  <th>Акција</th>
                </tr>
              </thead>
              <tbody>
                {this.state.messages
                  ? this.state.messages.rows.map((i) => {
                      let date = new Date(i.last_update).toLocaleString(
                        "en-GB"
                      );
                      return (
                        <tr key={i._id}>
                          <td>{i.user_full_name}</td>
                          <td>{i.user_phone}</td>
                          <td>
                            {i.last_update_from_user ? (
                              <i
                                className="material-icons pointer error"
                                title="Корисникот внесе коментар/прашање"
                              >
                                error
                              </i>
                            ) : null}
                            {i.isPicture ? (
                              <img
                                className="pointer thumbnail"
                                src={i.url}
                                onClick={() => window.open(i.url, "_blank")}
                              ></img>
                            ) : (
                              i.messages[0].message
                            )}
                          </td>
                          <td>{date}</td>
                          <td>
                            <Link
                              className="btn button-standard"
                              to={`/messages/${i._id}`}
                            >
                              <i className="material-icons">search</i>
                              Прегледај пораки
                            </Link>
                            {this.state.isSuperAdmin ? (
                              <Button
                                onClick={() => this.setCurrentMessage(i._id)}
                                className="btn btn-danger ml-2"
                              >
                                <i className="material-icons">delete</i>
                                Избриши
                              </Button>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={"< Претходна"}
              nextLabel={"Следна >"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={
                this.state.messages && this.state.messages.count
                  ? Math.ceil(this.state.messages.count / 10)
                  : 1
              }
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"list-group-pagination pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link z-index-0"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              disabledClassName={"page-item disabled"}
              activeClassName={"page-item active"}
              className="z-index-0"
            />
          </section>
        </Col>
      </Row>
    );
  }
}
