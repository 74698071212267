import Axios from "axios";
import Cookies from "js-cookie";
class AppointmentsAPI {
  async getAll(pageNumber, totalCount, searchText) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.post(
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_BASE_URL}/appointments/getAll`
          : `${process.env.REACT_APP_LOCAL_API_URL}/appointments/getAll`,
        { pageNumber, totalCount, searchText },
        configAPi
      );
      return r.data;
    } catch (err) {
      throw err;
    }
  }

  async getAppointmentTypes() {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.get(
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_BASE_URL}/appointments/get-types`
          : `${process.env.REACT_APP_LOCAL_API_URL}/appointments/get-types`,
        configAPi
      );
      return r.data;
    } catch (err) {
      throw err;
    }
  }

  async getAppoiintment(uniqueId) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.get(
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_BASE_URL}/appointments/get-appointment/${uniqueId}`
          : `${process.env.REACT_APP_LOCAL_API_URL}/appointments/get-appointment/${uniqueId}`,
        configAPi
      );
      return r.data;
    } catch (err) {
      throw err;
    }
  }

  async newAppointment(user, appointment, usePoints, admin, pointsToUse) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.post(
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_BASE_URL}/appointments/insert-new`
          : `${process.env.REACT_APP_LOCAL_API_URL}/appointments/insert-new`,
        { user, appointment, usePoints, admin, pointsToUse },
        configAPi
      );
      return r;
    } catch (err) {
      throw err;
    }
  }
}

export default new AppointmentsAPI();
