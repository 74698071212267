import React, { Component } from "react";
import { Row, Col, Form, FormControl, Button, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import AppointmentsAPI from "../api/appointments.api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Cookies from "js-cookie";

export default class Appointments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      totalCount: 10,
      searchText: "",
      isSuperAdmin: JSON.parse(Cookies.get("isSuperAdmin")),
    };

    this.getAppointments = this.getAppointments.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  async getAppointments(pageNumber) {
    try {
      const result = await AppointmentsAPI.getAll(
        pageNumber ? pageNumber : this.state.page,
        this.state.totalCount,
        this.state.searchText
      );
      this.setState({ appointments: result.appointments });
    } catch (error) {
      console.log(error);
      toast.error("Грешка при превземањето на прегледите");
    }
  }
  async componentDidMount() {
    await this.getAppointments();
  }

  async handlePageClick(e) {
    await this.getAppointments(e.selected + 1);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <Row className="table-section">
        <ToastContainer />
        <Col lg={12}>
          <figure className="icon-group">
            <h2 className="heading ml-3">Прегледи</h2>
          </figure>

          <Form inline onSubmit={this.handleSubmit}>
            <FormControl
              type="number"
              name="searchText"
              placeholder="Пребарај тел.број"
              className="mr-sm-2"
              onChange={this.handleChange}
            />
            <Button
              variant="btn mr-2 button-standard"
              onClick={() => this.getAppointments()}
            >
              <i className="material-icons">search</i>
              Пребарај
            </Button>
            <Button
              className="btn mr-2 button-standard"
              onClick={() => this.getAppointments()}
            >
              <i className="material-icons">refresh</i> Освежи
            </Button>
            <Link
              to="/new-appointment"
              className="btn mr-2 button-add-appointment"
            >
              <i className="material-icons">add_circle</i> Нов преглед
            </Link>
          </Form>
          <section className="list-box mt-3">
            <Table responsive="xl" className="mb-5">
              <thead className="table-head">
                <tr>
                  <th>Пациент</th>
                  <th>Тел број</th>
                  <th>Администратор</th>
                  <th>Тип на преглед</th>
                  <th>Дата</th>
                </tr>
              </thead>
              <tbody>
                {this.state.appointments
                  ? this.state.appointments.rows.map((i) => {
                    let date = new Date(i.date).toLocaleString('en-GB');
                      return (
                        <tr key={i._id}>
                          <td>{i.pacient_name}</td>
                          <td>{i.pacient_phone}</td>
                          <td>{i.inserted_by}</td>
                          <td>{i.appointment_type}</td>
                          <td>{date}</td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={"< Претходна"}
              nextLabel={"Следна >"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={
                this.state.appointments && this.state.appointments.count
                  ? Math.ceil(this.state.appointments.count / 10)
                  : 1
              }
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"list-group-pagination pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link z-index-0"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              disabledClassName={"page-item disabled"}
              activeClassName={"page-item active"}
              className="z-index-0"
            />
          </section>
        </Col>
      </Row>
    );
  }
}
