import React, { Component } from "react";
import { Row, Col, Form, FormControl, Button, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import UserApi from "../api/users.api";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class Admins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admins: null,
      modalIsOpen: false,
      currentAdmin: "",
      currentAdminId: "",
      userNameError: "",
      passwordError: "",
      totalCount: 10,
      searchText: "",
      currentPassword: "",
      page: 1,
    };
    this.getAdmins = this.getAdmins.bind(this);
    this.setIsOpen = this.setIsOpen.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.editAdmin = this.editAdmin.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  async getAdmins(pageNumber) {
    try {
      const result = await UserApi.getAllAdmins(
        pageNumber ? pageNumber : this.state.page,
        this.state.totalCount,
        this.state.searchText
      );
      this.setState({ admins: result.admins });
    } catch (error) {
      console.log(error);
      toast.error("Грешка при земањето на доктори");
    }
  }

  async editAdmin() {
    try {
      if (this.state.currentPassword !== "" && this.state.currentAdmin !== "") {
        await UserApi.editAdmin(
          this.state.currentAdminId,
          this.state.currentAdmin,
          this.state.currentPassword
        );
        this.closeModal();
        toast.success("Измените се успешно зачувани!");
        this.setState({
          passwordError: "",
          currentAdmin: "",
          currentAdminId: "",
          currentPassword: "",
        });
      } else {
        this.setState({ passwordError: "Име и лозинка се задолжителни" });
      }
    } catch (error) {
      console.log(error);
      toast.error("Грешка при изменувањето");
    }
  }

  async componentDidMount() {
    await this.getAdmins();
  }
  setIsOpen(value) {
    this.setState({ modalIsOpen: value });
  }

  setCurrentAdmin(currentAdmin, currentAdminId) {
    this.setState({
      currentAdmin: currentAdmin,
      currentAdminId: currentAdminId,
      modalIsOpen: true,
    });
  }

  async handlePageClick(e) {
    await this.getAdmins(e.selected + 1);
  }

  openModal() {
    this.setIsOpen(true);
  }
  closeModal() {
    this.setIsOpen(false);
    this.setState({ passwordError: "" });
  }
  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  render() {
    Modal.setAppElement("#root");
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };
    return (
      <Row className="table-section">
        <ToastContainer />
        <Col lg={12}>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            contentLabel="Example Modal"
            style={customStyles}
          >
            <Form>
              <Form.Group as={Row}></Form.Group>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="6" className="black">
                  Внесете Ново Корисничко име
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="Ново Корисничко име"
                    name="currentAdmin"
                    onChange={this.handleChange}
                    value={this.state.currentAdmin}
                  />
                  {this.state.passwordError ? (
                    <span className="error">{this.state.passwordError}</span>
                  ) : null}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Form.Label column sm="6" className="black">
                  Внесете Нова лозинка
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="Нова лозинка"
                    name="currentPassword"
                    onChange={this.handleChange}
                  />
                  {this.state.passwordError ? (
                    <span className="error">{this.state.passwordError}</span>
                  ) : null}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Button
                  onClick={this.editAdmin}
                  className="ml-3 mr-3 btn btn-success"
                >
                  Зачувај
                </Button>
                <Button onClick={this.closeModal} className="btn btn-danger">
                  Откажи
                </Button>
              </Form.Group>
            </Form>
          </Modal>

          <Form inline onSubmit={this.handleSubmit}>
            <FormControl
              type="text"
              placeholder="Пребарај админ"
              className="mr-sm-2"
              name="searchText"
              onChange={this.handleChange}
            />
            <Button
              variant="outline-dark mb-3 mt-3"
              onClick={() => this.getAdmins()}
            >
              Пребарај
            </Button>
          </Form>
          <section className="list-box mt-3">
            <Table responsive="xl" className="mb-5">
              <thead>
                <tr className="table-head">
                  <th>Име</th>
                  <th>Привилегии</th>
                  <th>Aкција</th>
                </tr>
              </thead>
              <tbody>
                {this.state.admins !== null
                  ? this.state.admins.rows.map((i) => {
                      return (
                        <tr key={i._id}>
                          <td>{i.user_name}</td>
                          <td>
                            {i.role == 1 ? "Целосен увид" : "Внес на бодови"}
                          </td>
                          <td>
                            <Button
                              onClick={() =>
                                this.setCurrentAdmin(i.user_name, i._id)
                              }
                              className="btn button-standard"
                            >
                              Измени
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={"< Претходна"}
              nextLabel={"Следна >"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={
                this.state.admins !== null
                  ? Math.ceil(this.state.admins.count / 10)
                  : 1
              }
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"list-group-pagination pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              disabledClassName={"page-item disabled"}
              activeClassName={"page-item active"}
            />
          </section>
        </Col>
      </Row>
    );
  }
}
