import Axios from "axios";
import Cookies from "js-cookie";

class UserApi {
  async getAll(pageNumber, totalCount, searchText) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.post(
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_BASE_URL}/users/all`
          : `${process.env.REACT_APP_LOCAL_API_URL}/users/all`,
        { pageNumber, totalCount, searchText },
        configAPi
      );
      return r.data;
    } catch (err) {
      throw err;
    }
  }

  async getAllAdmins(pageNumber, totalCount, searchText) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.post(
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_BASE_URL}/users/admins`
          : `${process.env.REACT_APP_LOCAL_API_URL}/users/admins`,
        { pageNumber, totalCount, searchText },
        configAPi
      );
      return r.data;
    } catch (err) {
      throw err;
    }
  }

  async editAdmin(adminId, userName, password) {
    try {
      const token = Cookies.get("jwt");
      const configAPi = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      await Axios.post(
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_BASE_URL}/users/edit-admin`
          : `${process.env.REACT_APP_LOCAL_API_URL}/users/edit-admin`,
        { adminId, userName, password },
        configAPi
      );
    } catch (error) {
      throw error;
    }
  }

  async getAllForExport() {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.get(
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_BASE_URL}/users/export-all`
          : `${process.env.REACT_APP_LOCAL_API_URL}/users/export-all`,
        configAPi
      );
      return r.data.users;
    } catch (err) {
      throw err;
    }
  }

  async deleteUser(userId) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.delete(
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_BASE_URL}/users/delete/${userId}`
          : `${process.env.REACT_APP_LOCAL_API_URL}/users/delete/${userId}`,
        configAPi
      );
      return r;
    } catch (err) {
      throw err;
    }
  }

  async findByPhone(phone) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.get(
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_BASE_URL}/users/get-by-phone/${phone}`
          : `${process.env.REACT_APP_LOCAL_API_URL}/users/get-by-phone/${phone}`,
        configAPi
      );
      return r;
    } catch (err) {
      throw err;
    }
  }
}

export default new UserApi();
