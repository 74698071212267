import React from "react";
import ReactExport from "react-export-excel";
import { Button } from "react-bootstrap";
import messagesApi from "../api/messages.api";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportMessages extends React.Component {
  constructor(props) {
    super(props);

    this.sate = {
      messages: [],
    };
  }

  async componentDidMount() {
    try {
      const messagesArr = await messagesApi.getAllForExport();
      this.setState({ messages: messagesArr.data.messages });
    } catch (error) {
      throw error;
    }
  }

  render() {
    return (
      <ExcelFile
        filename="Коментари/Прашања"
        element={
          <Button variant="btn mr-2 button-standard">
            <i className="material-icons">cloud_download</i>Експортирај
          </Button>
        }
      >
        {this.state && this.state.messages != null ? (
          <ExcelSheet data={this.state.messages} name="messages">
            <ExcelColumn label="Име на испраќач" value="full_name" />
            <ExcelColumn label="Тел број" value="phone" />
            <ExcelColumn label="Коментар/Прашање" value="message" />
            <ExcelColumn label="Испратено на" value="date" />
          </ExcelSheet>
        ) : null}
      </ExcelFile>
    );
  }
}
export default ExportMessages;
